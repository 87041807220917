import * as React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import SnakeGame from "../components/snake-game"

const isBrowser = typeof window !== "undefined"

const NotFoundPage = () => {
  return (
    <Layout>
      <Hero />
      <h3 className="position-relative-zindex">Page Not Found.</h3>
      {isBrowser && !navigator.userAgent.toLowerCase().match(/mobile/i) ? (
        <SnakeGame />
      ) : null}
    </Layout>
  )
}

export default NotFoundPage

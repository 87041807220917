import * as React from "react"
import { Button } from "react-bootstrap"

import {
  food,
  grid,
  griditem,
  restarttext,
  snakehead,
  snaketail,
} from "./snake-game.module.css"

class SnakeGame extends React.Component {
  state = {
    tickTime: 200,
    rows: 10,
    cols: 10,
    grid: [],
    food: {},
    snake: {
      head: {},
      tail: [],
    },
    currentDirection: "right",
    die: false,
    win: false,
    score: 0,
    scoreFactor: 10,
  }

  constructor(props) {
    super(props)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.resetGameState = this.resetGameState.bind(this)
  }

  getRandomGrid() {
    const result = {
      row: Math.floor(Math.random() * this.state.rows),
      col: Math.floor(Math.random() * this.state.cols),
    }

    if (
      (result.row === this.state.snake.head.row &&
        result.col === this.state.snake.head.col) ||
      this.state.snake.tail.some(
        ({ row, col }) => result.row === row && result.col === col
      )
    )
      return this.getRandomGrid()

    return result
  }

  // TODO: snake and food begins at the center
  getCenterOfGrid() {
    return {
      row: Math.floor((this.state.rows - 1) / 2),
      col: Math.floor((this.state.cols - 1) / 2),
    }
  }

  resetGrid(state = {}, sendBack = false) {
    if (!Object.keys(state).length) {
      state = this.state
    }

    const grid = []
    const { rows, cols, food, snake } = state

    for (let row = 0; row < rows; row++) {
      for (let col = 0; col < cols; col++) {
        const isFood = food.row === row && food.col === col
        const isHead = snake.head.row === row && snake.head.col === col
        let isTail = false
        snake.tail.forEach(t => {
          if (t.row === row && t.col === col) {
            isTail = true
          }
        })

        grid.push({
          row,
          col,
          isFood,
          isHead,
          isTail,
        })
      }
    }

    if (sendBack) {
      return grid
    } else {
      this.setState({
        grid,
      })
    }
  }

  resetGameState() {
    this.setState(state => {
      const newState = {
        ...state,
        food: this.getRandomGrid(),
        snake: {
          head: this.getCenterOfGrid(),
          tail: [],
        },
        currentDirection: "right",
        die: false,
        score: 0,
      }
      const grid = this.resetGrid(newState, true)
      return {
        ...newState,
        grid,
      }
    })

    this.resetGrid()

    // Set tick
    window.fnInterval = setInterval(() => {
      this.gameTick()
    }, this.state.tickTime)
  }

  gameTick() {
    this.setState(state => {
      let { currentDirection, snake, food } = state
      let { tail } = snake

      const { row, col } = state.snake.head
      let head = {
        row,
        col,
      }

      // When game over is shown, stop the tick
      if (state.die) {
        clearInterval(window.fnInterval)
      }

      // Snake eats
      tail.unshift({
        row: head.row,
        col: head.col,
      })

      // Snake does potty, only when not eating
      if (head.row === state.food.row && head.col === state.food.col) {
        food = this.getRandomGrid()
      } else {
        tail.pop()
      }

      // Snake moves head
      switch (currentDirection) {
        case "left":
          head.col--
          break

        case "up":
          head.row--
          break

        case "down":
          head.row++
          break

        case "right":
        default:
          head.col++
          break
      }

      const newState = {
        ...state,
        food,
        snake: {
          head,
          tail,
        },
      }

      // In new state, check if die conditions are met
      let die = newState.die
      let win = newState.win
      if (
        newState.snake.head.row < 0 ||
        newState.snake.head.row >= this.state.rows ||
        newState.snake.head.col < 0 ||
        newState.snake.head.col >= this.state.rows ||
        newState.snake.tail.some(
          ({ row, col }) =>
            newState.snake.head.row === row && newState.snake.head.col === col
        )
      ) {
        die = true
      } else if (newState.snake.tail.length >= newState.grid.length - 1) {
        win = true
      }

      const grid = this.resetGrid(newState, true)
      const score = newState.snake.tail.length * newState.scoreFactor

      return {
        ...newState,
        die,
        win,
        grid,
        score,
      }
    })
  }

  handleKeyPress(e) {
    let { currentDirection } = this.state

    switch (e.keyCode) {
      case 37:
        currentDirection = "left"
        break
      case 38:
        currentDirection = "up"
        break
      case 39:
      default:
        currentDirection = "right"
        break
      case 40:
        currentDirection = "down"
        break
    }

    const newState = {
      ...this.state,
      currentDirection,
    }
    const grid = this.resetGrid(newState, true)

    this.setState(state => {
      return {
        ...newState,
        grid,
      }
    })
  }

  componentDidMount() {
    document.body.addEventListener("keydown", this.handleKeyPress)

    this.setState(state => {
      const newState = {
        ...state,
        food: this.getRandomGrid(),
        snake: {
          head: this.getCenterOfGrid(),
          tail: state.snake.tail,
        },
      }
      const grid = this.resetGrid(newState, true)
      return {
        ...newState,
        grid,
      }
    })

    this.resetGrid()

    // Set tick
    window.fnInterval = setInterval(() => {
      this.gameTick()
    }, this.state.tickTime)
  }

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this.handleKeyPress)
    clearInterval(window.fnInterval)
  }

  render() {
    let gridContent = this.state.grid.map(grid => {
      return (
        <div
          key={grid.row.toString() + "-" + grid.col.toString()}
          className={
            grid.isHead
              ? `${griditem} ${snakehead}`
              : grid.isTail
              ? `${griditem} ${snaketail}`
              : grid.isFood
              ? `${griditem} ${food}`
              : griditem
          }
        />
      )
    })

    if (this.state.die || this.state.win) {
      gridContent = (
        <div className="text-center my-auto w-100">
          <h1 className="mb-3">{this.state.die ? "Game Over" : "You win!"}</h1>
          <Button
            variant="link"
            onClick={this.resetGameState}
            onKeyPress={e => (e.key === 13 ? this.resetGameState() : null)}
          >
            <h4 className={restarttext}>Restart?</h4>
          </Button>
        </div>
      )
    }
    return (
      <>
        <h2>Your score: {this.state.score}</h2>
        <p>Use arrow keys to play.</p>
        <div className={grid}>{gridContent}</div>
      </>
    )
  }
}

export default SnakeGame
